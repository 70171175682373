<template>
  <div class="container" v-loading="loading">
    <div class="col-xs-12 col-sm-12 col-md-12"></div>

    <div class="row course-part">
      <div class="update-title col-xs-12 col-sm-12 col-md-12 center">
        创意源-开发更新时间线
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div v-if="!user">
          <div class="block page-timeline">
            <el-timeline>
              <el-timeline-item timestamp="请登录" placement="top">
                <el-card>
                  <h4>登录后，才可以看到具体内容</h4>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>

        <div v-if="user">
          <el-timeline>
            <el-timeline-item timestamp="2022-07-22，V2.1.3" placement="top">
              <el-card>
                <p>新增账号模块的用户修改资料功能</p>
                <p>新增账号模块的用户修改密码功能</p>
              </el-card>
            </el-timeline-item>

            <el-timeline-item timestamp="2022-07-20，V2.1.2" placement="top">
              <el-card>
                <p>正式上线账号模块</p>
                <p>取消邀请码机制和创意币的计划</p>
              </el-card>
            </el-timeline-item>

            <el-timeline-item timestamp="2022-06-13，V2.1.1" placement="top">
              <el-card>
                <p>开始实施开发更新的记录机制</p>
                <p>上线内测账号模块，邀请码机制</p>
                <p>上线内测创意币模块</p>
              </el-card>
            </el-timeline-item>

            <el-timeline-item timestamp="2022-06-03，V2.1" placement="top">
              <el-card>
                <p>上线创意源2.1：基于版本2.0的计划，重构系统</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2021-10-04，V2.0" placement="top">
              <el-card>
                <p>上线创意源2.0：重构系统</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2019-10-28，V1.0" placement="top">
              <el-card>
                <p>上线创意源1.0：创意源诞生</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["user"],
  data() {
    return {
      list: null,
      loading: false,
    };
  },
  created() {
    this.getPageData();
  },
  methods: {
    async getPageData() {
      const response = await axios.post("/api/web/page/details/index");
      const items = Object.values(response.data.data.content);
      this.sliders = items[0];
      // console.log(this.user.data.id)
      // this.loading = false
    },
  },
};
</script>
